import React from "react"
import "../style/about.css"
import Layout from "../components/layout"

class KeyPlan extends React.Component {
    render() {
        return (
            <Layout>
                <br /><br /><br /><br />
                <div className="row p-5">
                    <div className="col-md-12">
                        <h2 className="text-center">2 BHK East Facing (Type 1)</h2>
                        <hr className="kp" />
                        <h5 className="text-center text-aspireblue">Area : 955 Sqft Price : 45Lac*</h5>
                    </div>
                    <div className="col-md-6 p-5" data-toggle="modal" data-target=".fkp">
                    <h4 className="text-center">Floor Plan</h4>
                        <hr className="thnk pb-2" />
                        <img src="../Images/Floor Plan/f2bef1.jpg" alt="Floor Plan" style={{ width: "inherit" }} />
                    </div>
                    <div className="col-md-6 p-5" data-toggle="modal" data-target=".ikp">
                    <h4 className="text-center">Isometric View</h4>
                        <hr className="thnk pb-2" />
                        <img src="../Images/Floor Plan/i2bef1.png" alt="Isometric Plan" style={{ width: "inherit" }} />
                    </div>
                </div>
                {/* Pop Up */}

                <div class="modal fade fkp" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/Floor Plan/f2bef1.jpg" alt="Floor Plan" />
                        </div>
                    </div>
                </div>

                <div class="modal fade ikp" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom modal-lg">
                        <div class="modal-content">
                            <img src="../Images/Floor Plan/i2bef1.png" alt="Isometric Plan" />
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
export default KeyPlan